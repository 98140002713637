import { useEffect } from 'react';

import useSnowplowModalViewTracking from '@app/hooks/snowplow/useSnowplowModalViewTracking';
import { checkIfCookieYesBannerCurrentlyShowing } from '@app/api/services/cookie-yes';
import useAppSelector from '@app/hooks/utils/useAppSelector';

const TrackCookieYesCookieBannerViews = () => {
  const trackSnowplowModalView = useSnowplowModalViewTracking();
  const cookieYesBannerInitialised = useAppSelector(
    state => state.appState.cookieYesBannerInitialised,
  );

  const trackViewOnCookieYesBannerInitialised = () => {
    const isCookieYesBannerShowing = checkIfCookieYesBannerCurrentlyShowing();

    if (isCookieYesBannerShowing) {
      trackSnowplowModalView('cookie_yes_cookie_banner');
    }
  };

  useEffect(() => {
    // Track view when the banner isOpen on CookieYes initialisation.
    // The banner defaults to being open on init until the user selects to
    // accept or deny our cookie policy.
    if (cookieYesBannerInitialised) {
      trackViewOnCookieYesBannerInitialised();
    }
  }, [cookieYesBannerInitialised]);

  return null;
};

export default TrackCookieYesCookieBannerViews;
