import { Translate } from 'next-translate';

import { Promo } from '@app/api/resources/Promo';

import { getPromoPeriodText } from '@app/services/promo-utils';

const getOnboardingOfferTitle = (t: Translate, onboardingPromo: Promo) => {
  const { type, amount_saved, plan_period, plain_text } = onboardingPromo;
  if (type === 'FixedDiscount') {
    return plain_text;
  }

  if (type === 'Discount') {
    if (plan_period === 'month') {
      return t(
        'common:common.subscribe_ctas.onboarding_offer.save_discount_percentage',
        {
          discount_percentage: amount_saved,
        },
      );
    } else if (plan_period === 'year') {
      return t(
        'common:common.subscribe_ctas.onboarding_offer.save_discount_percentage_per_year',
        {
          discount_percentage: amount_saved,
        },
      );
    }
  }

  return '';
};

const getOnboardingOfferSubtitle = (t: Translate, onboardingPromo: Promo) => {
  const { type, plan_period, discount_period_days } = onboardingPromo;
  let subtitle = '';

  if (type === 'Discount') {
    if (plan_period === 'month') {
      subtitle = subtitle.concat(
        t('common:common.subscribe_ctas.onboarding_offer.per_month'),
      );

      if (discount_period_days) {
        subtitle = subtitle.concat(
          ' ',
          t('common:common.subscribe_ctas.onboarding_offer.for_promo_period', {
            promo_period: getPromoPeriodText(onboardingPromo, t),
          }),
        );
      }
    }
  }

  return subtitle;
};

export const getOnboardingOfferHeading = (
  t: Translate,
  onboardingPromo: Promo,
) => {
  const title = getOnboardingOfferTitle(t, onboardingPromo);
  const subtitle = getOnboardingOfferSubtitle(t, onboardingPromo);

  return { title, subtitle };
};

export const getOnboardingOfferFooter = (
  t: Translate,
  onboardingPromo: Promo,
  geoLocation: string,
) => {
  const {
    type,
    plan_period,
    standard_price,
    discounted_price,
    discount_period_days,
  } = onboardingPromo;
  let onboardingOfferFooter = '';

  if (type === 'FixedDiscount') {
    if (plan_period === 'month') {
      onboardingOfferFooter = onboardingOfferFooter.concat(
        t(
          'common:common.subscribe_ctas.onboarding_offer.legal_footer_fixed_discount',
          {
            amount: standard_price,
            number_of_days: discount_period_days,
          },
        ),
        ' ',
        t('common:common.subscribe_ctas.onboarding_offer.legal_cancel_anytime'),
        ' ',
      );
    } else if (plan_period === 'year') {
      onboardingOfferFooter = onboardingOfferFooter.concat(
        t(
          'common:common.subscribe_ctas.onboarding_offer.legal_footer_fixed_discount_per_year',
          {
            amount: standard_price,
            number_of_days: discount_period_days,
          },
        ),
        ' ',
        t('common:common.subscribe_ctas.onboarding_offer.legal_cancel_anytime'),
        ' ',
      );
    }
  }

  if (type === 'Discount') {
    if (plan_period === 'month') {
      onboardingOfferFooter = onboardingOfferFooter.concat(
        t(
          'common:common.subscribe_ctas.onboarding_offer.legal_footer_discount',
          {
            discount_amount: discounted_price,
            number_of_days: discount_period_days,
            amount: standard_price,
          },
        ),
        ' ',
        t('common:common.subscribe_ctas.onboarding_offer.legal_cancel_anytime'),
        ' ',
      );
    } else if (plan_period === 'year') {
      onboardingOfferFooter = onboardingOfferFooter.concat(
        t(
          'common:common.subscribe_ctas.onboarding_offer.legal_footer_discount_per_year',
          {
            discount_amount: discounted_price,
            amount: standard_price,
          },
        ),
        ' ',
        t('common:common.subscribe_ctas.onboarding_offer.legal_cancel_anytime'),
        ' ',
      );
    }
  }

  if (['AR', 'BR'].includes(geoLocation)) {
    onboardingOfferFooter = onboardingOfferFooter.concat(
      t('common:common.subscribe_ctas.onboarding_offer.tax_argentina'),
      ' ',
    );
  }

  return onboardingOfferFooter;
};

const getPromoCtaCopy = (t: Translate, isUserLoggedIn: boolean) => {
  if (isUserLoggedIn) {
    return t(
      'common:common.subscribe_ctas.onboarding_offer.button_cta_logged_in',
    );
  }

  return t(
    'common:common.subscribe_ctas.onboarding_offer.button_cta_logged_out',
  );
};

export const getSubscribeCtaCopy = ({
  t,
  onboardingPromo,
  isUserLoggedIn,
  eligibleForFreeTrial,
  trialsEnabled,
  isSplashFormCTA = false,
  hidePrice = false,
  geoLocation,
}: {
  t: Translate;
  onboardingPromo: Promo;
  isUserLoggedIn: boolean;
  eligibleForFreeTrial: boolean;
  trialsEnabled: boolean;
  isSplashFormCTA: boolean;
  hidePrice: boolean;
  geoLocation: string;
}) => {
  if (onboardingPromo) {
    return getPromoCtaCopy(t, isUserLoggedIn);
  }

  const useTrialCopy =
    (!isUserLoggedIn || eligibleForFreeTrial) && trialsEnabled;

  if (isSplashFormCTA) {
    if (useTrialCopy) {
      return t('common:common.pitch_banner.get_started');
    } else if (hidePrice) {
      return t('common:common.subscribe_ctas.start_watching');
    }
    return t('common:common.buttons.watch_now');
  }

  if (useTrialCopy) {
    if (geoLocation === 'TR') {
      return t('common:common.subscribe_ctas.first_7_days_free');
    }
    return t('common:common.subscribe_ctas.try_7_days_free');
  }

  return t('common:common.subscribe_ctas.subscribe');
};

export const getSimpleSubscribeCtaCopy = (
  eligibleForTrial: boolean,
  geoLocation: string,
  t: Translate,
) => {
  if (eligibleForTrial) {
    if (geoLocation === 'TR') {
      return t('common:common.subscribe_ctas.first_7_days_free');
    }
    return t('common:common.subscribe_ctas.try_7_days_free');
  }
  return t(
    'common:common.subscribe_ctas.start_your_one_year_for_amount_get_started',
  );
};

export const shouldShowEmailFormCTA = (
  isSplashFormCTA: boolean,
  isAuthenticated: boolean,
  onboardingPromoActive: boolean,
  ignorePromo: boolean,
) => {
  if (isSplashFormCTA) {
    if (isAuthenticated) {
      return false;
    }

    if (onboardingPromoActive && !ignorePromo) {
      return true;
    }

    return true;
  }
  return false;
};
