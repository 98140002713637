import { CookieYesCookieConsent } from '@app/api/services/cookie-yes';

export const areMarketingCookiesAllowed = (): boolean => {
  if (window.getCkyConsent) {
    const cookieYesConsent = window.getCkyConsent() as CookieYesCookieConsent;
    return cookieYesConsent?.categories?.advertisement;
  }
  return null;
};

export const areAnalyticsCookiesAllowed = (): boolean => {
  if (window.getCkyConsent) {
    const cookieYesConsent = window.getCkyConsent() as CookieYesCookieConsent;
    return cookieYesConsent?.categories?.analytics;
  }
  return null;
};
