import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  checkIfCookieYesContainerRenderedIntoPage,
  CookieYesCookieConsent,
} from '@app/api/services/cookie-yes';

import { setLocalCookieYesCookieConsent } from '@app/actions/UserActions';

import { setCookieYesBannerInitialised } from '@app/actions/AppStateActions';

const InitialiseLocalBrowserCookieYesCookieConsentState = () => {
  const [cookieYesIsInitialised, setCookieYesIsInitialised] = useState(false);

  const dispatch = useDispatch();

  const isCookieYesInitialised = () =>
    window.getCkyConsent && checkIfCookieYesContainerRenderedIntoPage();

  const pollForCookieYesInitialisation = () => {
    setTimeout(() => {
      if (isCookieYesInitialised()) {
        setCookieYesIsInitialised(true);
      } else {
        pollForCookieYesInitialisation();
      }
    }, 1000);
  };

  useEffect(() => {
    if (isCookieYesInitialised()) {
      setCookieYesIsInitialised(true);
    } else {
      pollForCookieYesInitialisation();
    }
  }, []);

  useEffect(() => {
    if (cookieYesIsInitialised) {
      dispatch(setCookieYesBannerInitialised());

      const cookieYesConsent = window.getCkyConsent() as CookieYesCookieConsent;

      dispatch(
        setLocalCookieYesCookieConsent({
          functional: true,
          analytics: cookieYesConsent.categories.analytics,
          marketing: cookieYesConsent.categories.advertisement,
        }),
      );
    }
  }, [cookieYesIsInitialised]);

  return null;
};

export default InitialiseLocalBrowserCookieYesCookieConsentState;
