import { AudioTrack, Reel, TextTrack } from '@app/api/resources/Reel';

const aTextTrack: TextTrack = {
  id: 'text_subtitle_eng',
  display_name: 'English',
  language_code: 'eng',
  name: 'text_subtitle_eng',
  role: 'subtitle',
  label: 'English',
  language_code_iso639: 'eng',
  legacy_format: true,
};

export const someTextTracks: TextTrack[] = [
  aTextTrack,
  {
    id: 'text_subtitle_ita',
    name: 'text_subtitle_ita',
    display_name: 'Italian',
    role: 'subtitle',
    language_code: 'ita',
    label: 'Italian',
    language_code_iso639: 'ita',
    legacy_format: true,
  },
];

export const anAudioTrack: AudioTrack = {
  id: 'audio_main_deu',
  name: 'audio_main_deu',
  display_name: 'German',
  role: 'main',
  language_code: 'deu',
  language_code_iso639: 'deu',
};

export const someAudioTracks: AudioTrack[] = [
  anAudioTrack,
  {
    display_name: 'French',
    id: 'audio_main_fra',
    language_code: 'fra',
    name: 'audio_main_fra',
    role: 'main',
    language_code_iso639: 'fra',
  },
];

export const aReel: Reel = {
  id: 26476,
  credits_roll_at: 7717,
  duration: 7927,
  hd: true,
  text_tracks: someTextTracks,
  audio_tracks: someAudioTracks,
};

const anotherReel: Reel = {
  id: 264,
  credits_roll_at: 8700,
  duration: 8830,
  hd: false,
  text_tracks: someTextTracks,
  audio_tracks: someAudioTracks,
};

export const multipleReels = [aReel, anotherReel];
