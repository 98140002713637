import { animateScroll as scroll } from 'react-scroll';

import HttpService from '@app/api/http-service';
import { Meta } from '@app/api/pagination';
import { Comment } from '@app/api/resources/Comment';
import { Film } from '@app/api/resources/Film';
import { NotebookAuthor } from '@app/api/resources/notebook/NotebookAuthor';
import { ResponseWithError } from '@app/api/resources/Responses';
import { ObjectOfStrings } from '@app/api/utility-types';
import { getPaginationQS } from '@app/api/utils';

export type NotebookPost = {
  id: number;
  body: string;
  slug: string;
  title: string;
  type: string;
  comment_count: number;
  published_at: string;
  rating: number;
  summary: string;
  updated_at: string;
  url: string;
  display_type: string;
  spotlight: {
    summary: string;
    title: string;
  };
  spotlight_image_url: string;
  spotlight_square_image_url?: string;
  tags: string;
  review_film: Film;
  authors: NotebookAuthor[];
  canonical_url?: string;
  edit_post_url?: string;
  comments: {
    totalCount: number;
    nodes: Comment[];
  };
};

export type FilmNotebookPost = {
  id: number;
  summary: string;
  authors: {
    name: string;
    link: string;
  }[];
  type:
    | 'article'
    | 'review'
    | 'festival'
    | 'video'
    | 'column'
    | 'interview'
    | 'audio';
  image_url: {
    w690: string;
    standard: string;
    retina: string;
  };
  title: string;
  updated_at: string;
  quote: string;
  source: string;
  published_at: string;
  tags: string;
  comment_count: number;
  slug: string;
  web_url: string;
};

export type PostPages = {
  postIds: string[];
  pageMeta: Meta;
};

export type NotebookPostGridData = {
  pageInfo: Meta;
  notebookPosts: NotebookPost[];
  getNextPage: () => void;
  getPrevPage: () => void;
  goToPage: (pageNum: number) => void;
};

export type NotebookPostResponse = {
  posts: NotebookPost[];
  meta: Meta;
};

export type PageOfNotebookPosts = {
  pageOfNotebookPosts: NotebookPost[];
  pageMeta: Meta;
};

export const updatePageQueryStringAndScroll = (
  pageNum: number,
  searchText?: string,
) => {
  window.history.pushState(
    '',
    '',
    `${window.location.pathname}?${
      searchText ? `q=${searchText}&` : ''
    }page=${pageNum.toString()}`,
  );
  scroll.scrollToTop({
    duration: 300,
  });
};

export const getNotebookPosts = (
  httpContext: ObjectOfStrings,
  page: number,
  numPerPage: number,
): ResponseWithError<NotebookPostResponse> => {
  let url = '/notebook/posts';
  const paginationQS = getPaginationQS(page, numPerPage);
  if (paginationQS) {
    url = `${url}?${paginationQS}`;
  }
  return HttpService(httpContext).get(url);
};

export const getNotebookPost = (
  httpContext: ObjectOfStrings,
  postId: number | string,
): ResponseWithError<NotebookPost> =>
  HttpService(httpContext).get(`/notebook/posts/${postId}`);

export const getNotebookPostPreview = (
  httpContext: ObjectOfStrings,
  postId: number | string,
): ResponseWithError<NotebookPost> =>
  HttpService(httpContext).get(`/notebook/posts/${postId}/preview`);

export const getNotebookPostComments = (
  httpContext: ObjectOfStrings,
  postId: number,
): ResponseWithError<{ comments: Comment[] }> =>
  HttpService(httpContext).get(`/notebook/posts/${postId}/comments`);

export const getNotebookPostRelatedFilms = (
  httpContext: ObjectOfStrings,
  postId: number | string,
): ResponseWithError<{ films: Film[]; meta: Meta }> =>
  HttpService(httpContext).get(`/notebook/posts/${postId}/related_films`);

export const getNotebookPostFeatured = (
  httpContext: ObjectOfStrings,
): ResponseWithError<NotebookPost[]> =>
  HttpService(httpContext).get('/notebook/posts/featured');

export const getNotebookPostLatest = (
  httpContext: ObjectOfStrings,
  postId: number,
): ResponseWithError<NotebookPost[]> =>
  HttpService(httpContext).get(`/notebook/posts/${postId}/latest`);

export const getEditPostUrl = (post: NotebookPost) =>
  (post.slug && post.slug.length > 0 && `/admin/posts/${post.slug}/edit`) ||
  null;
