import { Translate } from 'next-translate';

import { Promo } from '@app/api/resources/Promo';

import TranslateHtml from '@app/components/TranslateHtml';

export const PROMO_DEFAULT_BG_IMAGE = `${process.env.assetPrefix}/splash/boogie_nights_darkened.jpg`;

export const getMubiShopUrl = (geoLocation: string) =>
  geoLocation === 'US'
    ? 'https://shop.mubi.com/en-us'
    : 'https://shop.mubi.com/';

export const getPromoTitle = (
  promo: Promo,
  t: Translate,
  plainText?: boolean,
) => {
  const { special_promo_splash_page } = promo;

  if (special_promo_splash_page) {
    const { header, partner_name } = special_promo_splash_page;

    if (header) {
      if (plainText) {
        // strip tags: https://stackoverflow.com/a/822464/217866
        return header.replace(/<[^>]*>?/gm, '');
      }
      // header sometimes contains <br> tags
      return <div dangerouslySetInnerHTML={{ __html: header }} />;
    }
    return t('promo:promo.title', { partner_name });
  }

  return null;
};

export const getPromoBody = (
  promo: Promo,
  t: Translate,
  plainText?: boolean,
) => {
  const {
    type,
    plan_period,
    amount_saved,
    discount_period,
    discounted_price,
    free_trial_period,
    special_promo_splash_page,
  } = promo;

  if (special_promo_splash_page) {
    const { body, body_html, partner_name } = special_promo_splash_page;

    if (body) {
      if (plainText) {
        return body;
      }
      return <div dangerouslySetInnerHTML={{ __html: body_html }} />;
    }
    if (type === 'Discount') {
      if (plainText) {
        return t(`promo:promo.body.discount.${plan_period}`, {
          partner_name,
          discount_percent: amount_saved,
        });
      }
      return (
        <TranslateHtml
          t={t}
          i18nKey={`promo:promo.body.discount.${plan_period}`}
          i18nVars={{
            partner_name,
            discount_percent: <strong>{amount_saved}</strong>,
          }}
        />
      );
    }
    if (type === 'Vip') {
      return t('promo:promo.body.vip');
    }
    if (type === 'FixedDiscount') {
      return t('promo:promo.body.fixed_discount', {
        partner_name,
        discount_period,
        discounted_price,
      });
    }
    if (type === 'FreeTrial') {
      const trialInfo = t('promo:promo.body.trial_info', {
        trial_period: free_trial_period,
      });
      if (plainText) {
        return t('promo:promo.body.trial', {
          partner_name,
          trial_info: trialInfo,
        });
      }
      return (
        <TranslateHtml
          t={t}
          i18nKey="promo:promo.body.trial"
          i18nVars={{
            partner_name,
            trial_info: <strong>{trialInfo}</strong>,
          }}
        />
      );
    }
  }
  return null;
};

export const getTwitterShareCopy = (promo: Promo, t: Translate) => {
  const {
    type,
    plan_period,
    amount_saved,
    free_trial_period = '',
    special_promo_splash_page,
  } = promo;

  if (special_promo_splash_page) {
    const { partner_name } = special_promo_splash_page;

    if (type === 'FixedDiscount') {
      // TODO: Lilly to provide this copy
      return '';
    }

    let copy;
    if (type === 'Discount') {
      copy = t(`promo:promo.share.discount.${plan_period}`, {
        discount_percent: amount_saved,
      });
    } else if (type === 'Vip' || type === 'FreeTrial') {
      copy = t('promo:promo.share.free_days', { period: free_trial_period });
    } else {
      copy = '';
    }
    if (partner_name) {
      const suffix = t('promo:promo.share.presented_by', { partner_name });
      return `${copy} ${suffix}`;
    }
    return copy;
  }

  return null;
};

/* 
This helper method returns a text according to this pattern:

1 month — singular, month
2-11 months — plural, months
12 months — singular, year

So for example a promo with:

  discount_period_days: 30 -> Return 1 month / one month
  discount_period_days: 90 -> Return 3 months / three months
  discount_period_days: 360 -> Return 1 year / one year
*/
export const getPromoPeriodText = (
  promo: Promo,
  t: Translate,
  useWordsForPeriodNumber = false,
) => {
  const { discount_period_days, discount_period } = promo;

  const discountPeriodNumber = (discount_period_days || discount_period) / 30;
  const discountPeriod = useWordsForPeriodNumber
    ? t(`onboarding:onboarding.shared.numbers.${discountPeriodNumber}`)
    : discountPeriodNumber;

  if (discountPeriodNumber === 1) {
    return t('onboarding:onboarding.shared.promo_period.singular_month', {
      amount: discountPeriod,
    });
  }
  if (discountPeriodNumber < 12) {
    return t('onboarding:onboarding.shared.promo_period.plural_months', {
      amount: discountPeriod,
    });
  }
  const discountPeriodNumberYears = discountPeriodNumber / 12;

  return t('onboarding:onboarding.shared.promo_period.singular_year', {
    amount: useWordsForPeriodNumber
      ? t(`onboarding:onboarding.shared.numbers.${discountPeriodNumberYears}`)
      : discountPeriodNumberYears,
  });
};
