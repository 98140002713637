import { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import { CookieConsent, persistUpdateUser } from '@app/api/resources/User';

import { CookieYesUpdatedConsent } from '@app/api/services/cookie-yes';

import {
  setLocalCookieYesCookieConsent,
  updateLoggedInUser,
} from '@app/actions/UserActions';

import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';
import useAppSelector from '@app/hooks/utils/useAppSelector';

const useUpdateCookieConsentOnCookieYesBannerInteraction = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const trackSnowplowClickEvent = useSnowplowClickTracker();

  const { isAuthenticated, httpContext, cookieYesBannerInitialised } =
    useAppSelector(
      state => ({
        isAuthenticated: state.user.isAuthenticated,
        httpContext: state.appState.httpContext,
        cookieYesBannerInitialised: state.appState.cookieYesBannerInitialised,
      }),
      shallowEqual,
    );

  const onConsentSet = (event: CustomEvent<CookieYesUpdatedConsent>) => {
    const updatedConsent = event.detail;

    updateCookiePrefsAfterBannerInteraction({
      analytics: !!updatedConsent?.accepted?.includes('analytics'),
      marketing: !!updatedConsent?.accepted?.includes('advertisement'),
    });
  };

  useEffect(() => {
    if (cookieYesBannerInitialised) {
      document.addEventListener('cookieyes_consent_update', onConsentSet);
    }

    return () => {
      document.removeEventListener('cookieyes_consent_update', onConsentSet);
    };
  }, [cookieYesBannerInitialised, router?.pathname]);

  const updateCookiePrefsAfterBannerInteraction = async (
    consent: CookieConsent,
  ) => {
    trackSnowplowClickEvent({
      data: {
        clickType: consent.analytics
          ? 'allow_analytics_cookies'
          : 'reject_analytics_cookies',
        element: 'cookie_banner',
      },
    });
    trackSnowplowClickEvent({
      data: {
        clickType: consent.marketing
          ? 'allow_marketing_cookies'
          : 'reject_marketing_cookies',
        element: 'cookie_banner',
      },
    });
    dispatch(setLocalCookieYesCookieConsent(consent));
    if (isAuthenticated) {
      try {
        const { data: user } = await persistUpdateUser(httpContext, {
          cookie_consent: consent,
        });
        dispatch(
          updateLoggedInUser({
            cookie_consent: user.cookie_consent,
          }),
        );
      } catch (error) {}
    }
  };
};

export default useUpdateCookieConsentOnCookieYesBannerInteraction;
