import {
  IssueEntitlement,
  MagazineSubscriptionStatus,
} from '@app/api/resources/notebook/NotebookMagazine';
import { PaymentMethod } from '@app/api/resources/PaymentMethod';
import { SubscriptionPlans } from '@app/api/resources/SubscriptionPlans';
import {
  CookieConsent,
  CurrentUser,
  SubscriptionCancellation,
} from '@app/api/resources/User';

import { LocalePath } from '@app/api/services/language';

import { ObjectOfAny, ObjectOfStrings } from '@app/types/utility-types';

import * as actionTypes from '@app/actionTypes';

export const setUserInitialisedOnPageLoad = () => ({
  type: actionTypes.SET_USER_INITIALISED_ON_PAGE_LOAD,
});

export const setGeoLocation = (geoLocation: string) => ({
  type: actionTypes.SET_USER_GEO_LOCATION,
  payload: {
    geoLocation,
  },
});

export const setCountryFullTimeZone = (countryFullTimeZone: string) => ({
  type: actionTypes.SET_COUNTRY_FULL_TIME_ZONE,
  payload: {
    countryFullTimeZone,
  },
});

export const setDismissedBanners = (dismissedBanners: ObjectOfStrings) => ({
  type: actionTypes.SET_DISMISSED_BANNERS,
  payload: {
    dismissedBanners,
  },
});

export const dismissABanner = (
  dismissedBanner: string,
  dismissedBannerTime: string,
) => ({
  type: actionTypes.DISMISS_A_BANNER,
  payload: {
    dismissedBanner,
    dismissedBannerTime,
  },
});

export const setCurrentLanguage = (currentLanguage: LocalePath) => ({
  type: actionTypes.SET_CURRENT_LANGUAGE,
  payload: {
    currentLanguage,
  },
});

export const setAcceptedLanguages = (acceptedLanguages: string) => ({
  type: actionTypes.SET_ACCEPTED_LANGUAGES,
  payload: {
    acceptedLanguages,
  },
});

export const logUserIn = (user: CurrentUser) => ({
  type: actionTypes.LOG_USER_IN,
  payload: {
    user,
  },
});

export const updateLoggedInUser = (updatedUserProperties: ObjectOfAny) => ({
  type: actionTypes.UPDATE_LOGGED_IN_USER,
  payload: {
    updatedUserProperties,
  },
});

export const logUserOut = () => ({
  type: actionTypes.LOG_USER_OUT,
});

export const addItemsToWatchList = watchListItems => ({
  type: actionTypes.ADD_ITEMS_TO_WATCH_LIST,
  payload: {
    watchListItems,
  },
});

export const setWatchList = watchList => ({
  type: actionTypes.SET_WATCH_LIST,
  payload: {
    watchList,
  },
});

export const addFilmToWatchList = watchListItem => ({
  type: actionTypes.ADD_TO_WATCH_LIST,
  payload: {
    watchListItem,
  },
});

export const removeFilmFromWatchList = (filmIdToRemove: number | string) => ({
  type: actionTypes.REMOVE_FROM_WATCH_LIST,
  payload: {
    filmIdToRemove,
  },
});

export const setSubscriptionPlans = (subscriptionPlans: SubscriptionPlans) => ({
  type: actionTypes.SET_SUBSCRIPTION_PLANS,
  payload: {
    subscriptionPlans,
  },
});

export const setSubscriptionPlansForUser = (
  subscriptionPlansForUser: SubscriptionPlans,
) => ({
  type: actionTypes.SET_SUBSCRIPTION_PLANS_FOR_USER,
  payload: {
    subscriptionPlansForUser,
  },
});

export const addUserRatings = ratingsByFilmId => ({
  type: actionTypes.SET_USER_RATINGS,
  payload: {
    ratingsByFilmId,
  },
});

export const removeUserRatingForFilm = (filmId: number) => ({
  type: actionTypes.REMOVE_USER_RATING_FOR_FILM,
  payload: {
    filmId,
  },
});

export const setUseUsEnglish = (useUsEnglish: boolean) => ({
  type: actionTypes.SET_USE_US_ENGLISH,
  payload: {
    useUsEnglish,
  },
});

export const setMagazineSubStatus = (
  magazineSubStatus: MagazineSubscriptionStatus,
  issueEntitlements: IssueEntitlement[],
) => ({
  type: actionTypes.SET_MAGAZINE_SUBSCRIPTION,
  payload: {
    magazineSubStatus,
    issueEntitlements,
  },
});

export const setPaymentMethodForUser = (paymentMethod: PaymentMethod) => ({
  type: actionTypes.SET_PAYMENT_METHOD_FOR_USER,
  payload: {
    paymentMethod,
  },
});

export const setLocalCookieYesCookieConsent = (
  cookieConsent: CookieConsent,
) => ({
  type: actionTypes.SET_LOCAL_COOKIE_YES_COOKIE_CONSENT,
  payload: {
    cookieConsent,
  },
});

export const setParentalControlForUser = ({
  contentRatingId,
  pinCode,
}: {
  contentRatingId: number;
  pinCode?: string;
}) => ({
  type: actionTypes.SET_PARENTAL_CONTROL_FOR_USER,
  payload: {
    contentRatingId,
    pinCode,
  },
});

export const setSubscriptionCancellation = (
  subscriptionCancellation: SubscriptionCancellation,
) => ({
  type: actionTypes.SET_SUBSCRIPTION_CANCELLATION,
  payload: {
    subscriptionCancellation,
  },
});
