import Router from 'next/router';
import { IncomingMessage, ServerResponse } from 'http';
import isbot from 'isbot';

import {
  getTwoCharLocaleFromLocaleString,
  isValidLocalePath,
} from '@app/api/resources/Language';

import { isValidCountryCode } from '@app/services/countries';
import { gtmVirtualPageView } from '@app/services/gtm';
import { removeUndefinedPropertiesFromObject } from '@app/services/utils';

import { Context } from '@app/types/common';
import { ObjectOfAny } from '@app/types/utility-types';

import { I18nUrlData } from '@app/hooks/helpers/useI18nUrlData';

const URLS_WITH_TWO_CHARS_IN_FIRST_PART_OF_PATH = ['go', 'lg', 'tv'];

export const initRouteChangeListener = () =>
  Router.events.on('routeChangeStart', () => {
    // setTimeout workaround needed as per NextJS issue https://github.com/zeit/next.js/issues/6025
    setTimeout(() => {
      gtmVirtualPageView();
    });
  });

const getLocalePrefixFromLanguage = (language: string) => {
  const twoCharLocale = getTwoCharLocaleFromLocaleString(language);
  return `/${twoCharLocale}`;
};

const getCountryPathPrefix = (country: string) =>
  isValidCountryCode(country) ? `/${country.toLowerCase()}` : '';

export const doesAsPathIncludeCountryCode = (
  asPath: string,
  isServer: boolean = true,
) => {
  // asPath never has the language prefix on server, only the country (vanityPath). Include lang on client

  let pathToCheck = isServer
    ? asPath
    : asPath.substring(asPath.substring(1).indexOf('/') + 1); // remove lang from path

  // Path without query params
  pathToCheck = pathToCheck.split('?')[0];

  if (
    pathToCheck.length > 3 &&
    new RegExp('^/[a-zA-Z]{2}/').test(pathToCheck) &&
    !URLS_WITH_TWO_CHARS_IN_FIRST_PART_OF_PATH.includes(
      pathToCheck.substring(1, 3),
    ) &&
    isValidCountryCode(pathToCheck.substring(1, 3))
  ) {
    // asPath includes the country code
    return true;
  }

  if (
    pathToCheck.length <= 3 &&
    new RegExp('^/[a-zA-Z]{2}').test(pathToCheck) &&
    !URLS_WITH_TWO_CHARS_IN_FIRST_PART_OF_PATH.includes(
      pathToCheck.substring(1, 3),
    ) &&
    isValidCountryCode(pathToCheck.substring(1, 3))
  ) {
    // asPath includes the country code
    return true;
  }

  return false;
};

export const getCountryCodeFromPath = (
  asPath: string,
  isServer: boolean = true,
) => {
  if (doesAsPathIncludeCountryCode(asPath, isServer)) {
    const pathWithoutQueryParams = asPath.split('?')[0];
    const path = pathWithoutQueryParams.split('/');
    return isServer ? path[1] : path[2];
  }
  return false;
};

export const getFullRouteUrl = ({
  url,
  i18nUrlData: { currentLanguage, geoLocation },
  includeDomain = true,
  queryParams = null,
}: {
  url: string;
  i18nUrlData: I18nUrlData;
  includeDomain?: boolean;
  queryParams?: Record<string, string>;
}) => {
  const urlWithoutI18nPrefix = removeLanguageAndCountryPrefixFromPath(url);

  const shouldUrlHaveCountryPrefix =
    shouldPathHaveCountryPrefix(urlWithoutI18nPrefix);

  const pathLocale = getLocalePrefixFromLanguage(currentLanguage);
  const pathCountry = getCountryPathPrefix(geoLocation);
  const domain = includeDomain ? process.env.domain || '' : '';

  let queryParamString = '';
  if (queryParams) {
    const searchParams = new URLSearchParams(queryParams);
    queryParamString = searchParams.toString();
  }

  return `${domain}${pathLocale}${
    shouldUrlHaveCountryPrefix ? pathCountry : ''
  }${urlWithoutI18nPrefix === '/' ? '' : urlWithoutI18nPrefix}${
    queryParamString ? `?${queryParamString}` : ''
  }`;
};

export const doServerRedirect = (
  res: ServerResponse<IncomingMessage>,
  pathWithI18nPrefix: string,
  statusCode: number = 302,
) => {
  res.writeHead(statusCode, {
    Location: pathWithI18nPrefix,
  });
  res.end();
};

export const doClientRedirect = (pathWithI18nPrefix: string) => {
  window.location.href = pathWithI18nPrefix;
};

export const doRedirect = (
  isServer: boolean,
  pathWithI18nPrefix: string,
  res: ServerResponse<IncomingMessage>,
  statusCode?: number,
) => {
  if (isServer) {
    doServerRedirect(res, pathWithI18nPrefix, statusCode);
  } else {
    doClientRedirect(pathWithI18nPrefix);
  }
};

/**
 * For eg: /showing?b=bye&a=hello to /showing?a=hello&b=bye
 */
export const getUrlWithAlphaOrderedQueryParamsForCanonicalUrl = (
  pathname: string,
  i18nUrlData: I18nUrlData,
  query: ObjectOfAny,
) => {
  const searchParams = new URLSearchParams(
    removeUndefinedPropertiesFromObject(query),
  );
  searchParams.sort();

  const pathnameWithoutPrefix =
    removeLanguageAndCountryPrefixFromPath(pathname);

  let url = pathnameWithoutPrefix;
  if (searchParams.toString().length > 0) {
    url = `${pathnameWithoutPrefix}?${searchParams.toString()}`;
  }

  const fullUrl = getFullRouteUrl({
    url,
    i18nUrlData,
  });

  return fullUrl;
};

export const removeLanguageAndCountryPrefixFromPath = (path: string) => {
  if (
    path.length > 6 &&
    new RegExp('^/[a-zA-Z]{2}/[a-zA-Z]{2}/').test(path) &&
    !URLS_WITH_TWO_CHARS_IN_FIRST_PART_OF_PATH.includes(path.substring(4, 6))
  ) {
    // url has country and language
    return path.substring(6);
  }

  if (
    path.length === 6 &&
    new RegExp('^/[a-zA-Z]{2}/[a-zA-Z]{2}').test(path) &&
    !URLS_WITH_TWO_CHARS_IN_FIRST_PART_OF_PATH.includes(path.substring(4, 6)) &&
    isValidLocalePath(path.substring(1, 3)) &&
    isValidCountryCode(path.substring(4, 6))
  ) {
    // url is splash with country and language
    return '/';
  }

  if (
    path.length > 3 &&
    new RegExp('^/[a-zA-Z]{2}/').test(path) &&
    !URLS_WITH_TWO_CHARS_IN_FIRST_PART_OF_PATH.includes(path.substring(1, 3))
  ) {
    // url has just language or just country
    return path.substring(3);
  }

  if (
    path.length === 3 &&
    new RegExp('^/[a-zA-Z]{2}').test(path) &&
    !URLS_WITH_TWO_CHARS_IN_FIRST_PART_OF_PATH.includes(path.substring(1, 3)) &&
    isValidLocalePath(path.substring(1, 3))
  ) {
    // url is splash with language
    return '/';
  }

  if (
    path.length === 3 &&
    new RegExp('^/[a-zA-Z]{2}').test(path) &&
    !URLS_WITH_TWO_CHARS_IN_FIRST_PART_OF_PATH.includes(path.substring(1, 3)) &&
    isValidCountryCode(path.substring(1, 3))
  ) {
    // url is splash with country
    return '/';
  }
  return path;
};

const previousPathnames = {
  showing: '^/showing$',
  filmDetails: '^/films/[^/]*$',
  collection: '^/collections/[^/]*$',
  about: '^/about$',
  memberships: '^/memberships$',
  student: '^/student$',
  go: '^/go$',
  splash: '^/$',
};

export const shouldPathHaveCountryPrefix = (path: string) => {
  let shouldHaveCountryPrefix = false;

  const pathWithoutQueryParams = path.split('?')[0];

  const pathWithLangOrCountryPrefixOrQueryParam =
    removeLanguageAndCountryPrefixFromPath(pathWithoutQueryParams).trim();

  Object.values(previousPathnames).forEach(previousPathname => {
    if (
      new RegExp(previousPathname).test(pathWithLangOrCountryPrefixOrQueryParam)
    ) {
      shouldHaveCountryPrefix = true;
    }
  });

  return shouldHaveCountryPrefix;
};

export const handleCountryPrefixRedirect = (ctx: Context) => {
  const { asPath, store, req, isServer } = ctx;
  const userAgent = req?.headers?.['user-agent'];
  let redirectAsCountryPrefixInvalidOrMissing = false;

  const currentState = store.getState();
  const geoLocation = currentState?.user?.geoLocation;
  const countryInPath = getCountryCodeFromPath(asPath, isServer);

  if (!doesAsPathIncludeCountryCode(asPath, isServer)) {
    redirectAsCountryPrefixInvalidOrMissing = true;
  }

  const allowToRedirect = process.env.CI || !isbot(userAgent);

  if (
    allowToRedirect &&
    isValidCountryCode(geoLocation) &&
    countryInPath &&
    countryInPath !== geoLocation?.toLowerCase()
  ) {
    redirectAsCountryPrefixInvalidOrMissing = true;
  }

  return redirectAsCountryPrefixInvalidOrMissing;
};

/**
 * For eg: /es/showing to /fr/showing
 */
export const changeLanguagePrefixOfUrl = (
  currentPath: string,
  newLocale: string,
  country?: string,
) => {
  if (currentPath) {
    let pathnameWithoutPrefix =
      removeLanguageAndCountryPrefixFromPath(currentPath);

    if (pathnameWithoutPrefix !== '/' && pathnameWithoutPrefix.endsWith('/')) {
      // Strip trailing slash
      pathnameWithoutPrefix = pathnameWithoutPrefix.substring(
        0,
        pathnameWithoutPrefix.length - 1,
      );
    }

    return `/${newLocale}${
      shouldPathHaveCountryPrefix(pathnameWithoutPrefix)
        ? `/${country?.toLocaleLowerCase()}`
        : ''
    }${pathnameWithoutPrefix === '/' ? '' : pathnameWithoutPrefix}`;
  }

  return '/';
};

export const stripDomainFromUrl = (url: string): string =>
  url.replace(/^.*\/\/[^/]+/, '').trim();

export const stripVanityPrefix = pathname => {
  if (typeof pathname === 'string') {
    if (pathname === '/[vanityPath]') {
      // Only strip when functioning as a prefix
      return pathname;
    }
    return pathname.replace('/[vanityPath]', '');
  }
  return pathname;
};

export const checkPathnamesAreEqual = (
  pathnameOne: string,
  pathnameTwo: string,
) => stripVanityPrefix(pathnameOne) === stripVanityPrefix(pathnameTwo);

export const checkPathnameMatches = (pathname: string, regExp: RegExp) =>
  stripVanityPrefix(pathname).match(regExp);

export const checkPathnameStartsWith = (
  pathname: string = '',
  startsWith: string,
) => stripVanityPrefix(pathname).startsWith(startsWith);

export const checkPathnameArrayIncludesPathname = (
  pathnameArray: string[],
  pathname: string,
) => {
  const pathnameArrayWithoutI18nPrefix = pathnameArray.map(pathnameInArray =>
    stripVanityPrefix(pathnameInArray),
  );
  return pathnameArrayWithoutI18nPrefix.includes(stripVanityPrefix(pathname));
};

export const checkPathnameStartsWithPathnameArray = (
  pathnameArray: string[],
  pathname: string,
) => {
  const pathnameArrayWithoutI18nPrefix = pathnameArray.map(pathnameInArray =>
    stripVanityPrefix(pathnameInArray),
  );
  return pathnameArrayWithoutI18nPrefix.find(pathnameWithoutI18nPrefix =>
    stripVanityPrefix(pathname).startsWith(pathnameWithoutI18nPrefix),
  );
};

export const shouldRedirectToRemoveCountryPrefix = (asPath: string) =>
  doesAsPathIncludeCountryCode(asPath) && !shouldPathHaveCountryPrefix(asPath);

export const shouldRedirectToAddCountryPrefix = (asPath: string) =>
  !doesAsPathIncludeCountryCode(asPath) && shouldPathHaveCountryPrefix(asPath);

export const shouldRedirectToSwapCountryPrefix = (
  asPath: string,
  redirectingCountry: string,
) =>
  doesAsPathIncludeCountryCode(asPath) &&
  shouldPathHaveCountryPrefix(asPath) &&
  !redirectingCountry;
