import { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';

import { CookieConsent, persistUpdateUser } from '@app/api/resources/User';

import { CookieYesCookieConsent } from '@app/api/services/cookie-yes';

import {
  setLocalCookieYesCookieConsent,
  updateLoggedInUser,
} from '@app/actions/UserActions';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const useAlignStoredUserAccountCookieConsentWithLocalCookieYesCookieConsent =
  () => {
    const dispatch = useDispatch();

    const {
      storedUserCookieConsentPref,
      isAuthenticated,
      httpContext,
      cookieYesBannerInitialised,
    } = useAppSelector(
      state => ({
        storedUserCookieConsentPref: state.user?.user?.cookie_consent ?? null,
        isAuthenticated: state.user.isAuthenticated,
        httpContext: state.appState.httpContext,
        cookieYesBannerInitialised: state.appState.cookieYesBannerInitialised,
      }),
      shallowEqual,
    );

    const updateCookiePrefsStoredForUser = async (consent: CookieConsent) => {
      try {
        dispatch(setLocalCookieYesCookieConsent(consent));
        const { data: user } = await persistUpdateUser(httpContext, {
          cookie_consent: consent,
        });
        dispatch(
          updateLoggedInUser({
            cookie_consent: user.cookie_consent,
          }),
        );
      } catch (error) {}
    };

    useEffect(() => {
      if (
        cookieYesBannerInitialised &&
        isAuthenticated &&
        storedUserCookieConsentPref
      ) {
        let cookieYesConsent: CookieYesCookieConsent;
        if (window.getCkyConsent) {
          cookieYesConsent = window.getCkyConsent();
        }

        if (
          cookieYesConsent &&
          (storedUserCookieConsentPref.analytics !==
            cookieYesConsent?.categories?.analytics ||
            storedUserCookieConsentPref.marketing !==
              cookieYesConsent?.categories?.advertisement)
        ) {
          // We need to align the user's stored cookie consent prefs with what's set locally in the browser
          updateCookiePrefsStoredForUser({
            analytics: cookieYesConsent.categories.analytics,
            marketing: cookieYesConsent.categories.advertisement,
          });
        }
      }
    }, [
      storedUserCookieConsentPref?.analytics,
      storedUserCookieConsentPref?.marketing,
      cookieYesBannerInitialised,
      isAuthenticated,
    ]);
  };

export default useAlignStoredUserAccountCookieConsentWithLocalCookieYesCookieConsent;
