import { shallowEqual } from 'react-redux';
import { useRouter } from 'next/router';

import {
  getSnowplowPageType,
  trackSnowplowModalView,
} from '@app/services/snowplow';

import useAppSelector from '@app/hooks/utils/useAppSelector';

type ModalTypes =
  | 'credits_overlay'
  | 'marketing_email_prompt'
  | 'cookie_yes_cookie_banner';

const useSnowplowModalViewTracking = () => {
  const router = useRouter();

  const { httpContext, countryCode, vanityPathPageType } = useAppSelector(
    state => ({
      httpContext: state.appState.httpContext,
      countryCode: state.user.geoLocation,
      vanityPathPageType: state.appState.pageState?.vanityPath?.pageType,
    }),
    shallowEqual,
  );

  const doTrackSnowplowModalView = async (
    modalType: ModalTypes,
    callback?: () => void,
  ) => {
    const pageType = await getSnowplowPageType(router, vanityPathPageType);
    trackSnowplowModalView(
      {
        modal_type: modalType,
        ...pageType,
        country_code: countryCode,
      },
      callback,
      httpContext,
    );
  };

  return doTrackSnowplowModalView;
};

export default useSnowplowModalViewTracking;
