import useAlignStoredUserAccountCookieConsentWithLocalCookieYesCookieConsent from '@app/hooks/helpers/cookie-yes/useAlignStoredUserAccountCookieConsentWithLocalCookieYesCookieConsent';
import useUpdateCookieConsentOnCookieYesBannerInteraction from '@app/hooks/helpers/cookie-yes/useUpdateCookieConsentOnCookieYesBannerInteraction';

const MonitorCookieYesCookieConsentChanges = () => {
  useAlignStoredUserAccountCookieConsentWithLocalCookieYesCookieConsent();
  useUpdateCookieConsentOnCookieYesBannerInteraction();

  return null;
};

export default MonitorCookieYesCookieConsentChanges;
