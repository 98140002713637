export type CookieYesCookieConsent = {
  activeLaw: string;
  categories: {
    necessary: boolean;
    functional: boolean;
    analytics: boolean;
    performance: boolean;
    advertisement: boolean;
  };
  isUserActionCompleted: boolean;
  consentID: string;
  languageCode: string;
};

type CookieYesConsentCategories =
  | 'necessary'
  | 'functional'
  | 'analytics'
  | 'performance'
  | 'advertisement';

export type CookieYesUpdatedConsent = {
  accepted: Array<CookieYesConsentCategories>;
  rejected: Array<CookieYesConsentCategories>;
};

export const checkIfCookieYesBannerCurrentlyShowing = () => {
  const cookieYesBannerContainer = document.getElementsByClassName(
    'cky-consent-container',
  )?.[0];

  if (cookieYesBannerContainer?.classList) {
    const isCookieYesBannerShowing =
      !cookieYesBannerContainer.classList.contains('cky-hide');

    return isCookieYesBannerShowing;
  }

  return null;
};

// CookieYes renders a container element in to the page
// independent of whether the banner is showing to the user or not.
export const checkIfCookieYesContainerRenderedIntoPage = () => {
  const cookieYesBannerContainer = document.getElementsByClassName(
    'cky-consent-container',
  )?.[0];

  return !!cookieYesBannerContainer;
};
