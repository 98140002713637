import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import * as Sentry from '@sentry/nextjs';

import { getSubscriptionUpgradeCost } from '@app/api/resources/SubscriptionPlans';

import {
  getSubscribeCtaCopy,
  shouldShowEmailFormCTA,
} from '@app/services/subscribe-cta';

import { OnboardingContext } from '@app/reducers/AppStateReducer';

import useOnboardingTrigger from '@app/hooks/helpers/useOnboardingTrigger';
import useAppSelector from '@app/hooks/utils/useAppSelector';

const NOT_INITIALISED = 'NOT_INITIALISED';

type OnboardingSignupCtaButtonContainerProps = {
  children: (props: {
    showModal: (onboardingContext: OnboardingContext) => void;
    context: OnboardingContext;
    ctaButtonText: string;
    disableButton: boolean;
    showEmailFormCTA: boolean;
    isOnboardingOfferCTA: boolean;
  }) => JSX.Element;
  ignorePromo?: boolean;
  forcePlan?: string;
  redirectUrl?: string;
  initialEmail?: string;
  isSplashFormCTA?: boolean;
  hidePrice?: boolean;
};

const OnboardingSignupCtaButtonContainer = ({
  children,
  ignorePromo = false,
  forcePlan = null,
  redirectUrl = null,
  initialEmail = null,
  isSplashFormCTA = false,
  hidePrice = false,
}: OnboardingSignupCtaButtonContainerProps) => {
  const { t } = useTranslation('common');
  const onboardingTrigger = useOnboardingTrigger();

  const {
    isUserLoggedIn,
    eligibleForFreeTrial,
    isAuthenticated,
    activeSubscriber,
    currentPlan,
    expiresAt,
    activeRecurringSubscriber,
    httpContext,
    trialsEnabled,
    onboardingPromo,
    geoLocation,
  } = useAppSelector(
    state => ({
      isUserLoggedIn: state.user.isAuthenticated,
      eligibleForFreeTrial: state.user.user?.eligible_for_free_trial,
      activeSubscriber: state.user.activeSubscriber,
      isAuthenticated: state.user.isAuthenticated,
      currentPlan: state.user.user?.current_plan,
      expiresAt: state.user.user?.subscription?.expires_at,
      activeRecurringSubscriber: state.user.user?.active_recurring_subscriber,
      httpContext: state.appState.httpContext,
      trialsEnabled: state.appState.trialsEnabled,
      onboardingPromo: state.appState.onboardingConfig?.promo,
      geoLocation: state.user.geoLocation,
    }),
    shallowEqual,
  );

  const onboardingPromoActive = !!onboardingPromo;
  const showEmailFormCTA = shouldShowEmailFormCTA(
    isSplashFormCTA,
    isAuthenticated,
    onboardingPromoActive,
    ignorePromo,
  );

  const [upgradeFee, setUpgradeFee] = useState(NOT_INITIALISED);
  let context: OnboardingContext;
  let shouldDisableButton: boolean;
  let isOnboardingOfferCTA = null;

  if (activeSubscriber) {
    context = {
      flow: 'changePlanNew',
      contextData: {
        currentPlan,
        activeRecurringSubscriber,
        renewalDate: expiresAt,
        fee: upgradeFee,
        redirectUrl,
      },
    };
    shouldDisableButton = upgradeFee === NOT_INITIALISED;
  } else if (onboardingPromoActive && !ignorePromo) {
    isOnboardingOfferCTA = true;

    context = {
      flow: 'promoNew',
      specialPromo: onboardingPromo,
      contextData: {
        forcePlan,
        redirectUrl,
        initialEmail,
      },
    };
  } else if (!trialsEnabled) {
    context = {
      flow: 'subscribeFromEmailForm',
      contextData: {
        forcePlan,
        redirectUrl,
        initialEmail,
      },
    };
  } else {
    context = {
      flow: showEmailFormCTA ? 'trialFromEmailForm' : 'trialNew',
      contextData: {
        forcePlan,
        redirectUrl,
        initialEmail,
      },
    };
  }

  const ctaButtonText = getSubscribeCtaCopy({
    t,
    onboardingPromo: ignorePromo ? null : onboardingPromo,
    isUserLoggedIn,
    eligibleForFreeTrial,
    trialsEnabled,
    isSplashFormCTA,
    hidePrice,
    geoLocation,
  });

  useEffect(() => {
    const initialiseUpgradePathRequest = async () => {
      try {
        const { data: subscriptionUpgrade } = await getSubscriptionUpgradeCost(
          httpContext,
        );
        setUpgradeFee(subscriptionUpgrade?.fee);
      } catch (error) {
        Sentry.captureException(error, {
          tags: { error_type: 'failed-subscription-upgrade-fee' },
        });
      }
    };
    if (activeSubscriber) {
      setUpgradeFee(NOT_INITIALISED);
      initialiseUpgradePathRequest();
    }
  }, [activeSubscriber]);

  const showModal = (onboardingContext: OnboardingContext) => {
    onboardingTrigger(onboardingContext.flow, onboardingContext);
  };

  return children({
    showModal,
    context,
    ctaButtonText,
    disableButton: shouldDisableButton,
    showEmailFormCTA,
    isOnboardingOfferCTA,
  });
};

export default OnboardingSignupCtaButtonContainer;
