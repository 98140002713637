import { Response } from 'redaxios';

import HttpService from '@app/api/http-service';
import { Meta } from '@app/api/pagination';
import { AppStartUp, PromoBanner } from '@app/api/resources/AppConfig';
import { CastMember } from '@app/api/resources/CastMember';
import { Country } from '@app/api/resources/Countries';
import { Film, FilmRecommendation } from '@app/api/resources/Film';
import { CancellationOffer, Promo } from '@app/api/resources/Promo';
import {
  SubscriptionPlan,
  SubscriptionPlans,
  SubscriptionStatus,
} from '@app/api/resources/SubscriptionPlans';
import { ObjectOfStrings } from '@app/api/utility-types';

import { Locale } from '@app/api/services/language';

export type ReferralsList = {
  referrals: User[];
  meta: Meta;
};

export type Referral = {
  referral_promo: {
    id: number;
    campaign: string;
    code: string;
    disabled: boolean;
    free_trial_period: number;
    plain_text: string;
    plan_level: string;
    plan_period: string;
    type: string;
  };
  referral_token: {
    token: string;
    user: User;
    redeemable: boolean;
  };
};

export type LifecyclePhase =
  | 'Trialist'
  | 'Subscriber'
  | 'Paused Subscriber'
  | 'Planned Leaver'
  | 'Voluntary Leaver'
  | 'Involuntary Leaver'
  | 'Registered User'
  | 'Anonymous Visitor';

export type CookieConsent = {
  functional?: boolean;
  analytics: boolean;
  marketing: boolean;
};

type ReasonForCancellation = {
  reason_for_cancellation: string;
  reason_for_cancellation_details?: string;
};

export type CurrentPlan = keyof SubscriptionPlans;

export type CurrentUser = {
  id: number;
  email: string;
  active_subscriber: boolean;
  activated_mobile_device: boolean;
  receive_newsletter: boolean;
  fb_user_id: string;
  is_admin: boolean;
  is_vip: boolean;
  has_password: boolean;
  birth_date: string;
  eligible_for_yearly_offer: boolean;
  private: boolean;
  braze_user_id: number;
  used_mubi_go: boolean;
  can_cancel_subscription: boolean;
  can_change_subscription_plan: boolean;
  can_pause_subscription: boolean;
  display_content_warnings: boolean;
  psn: {
    presence: boolean;
    sharing: boolean;
  };
  communication_opt_out: boolean;
  receive_film_newsletter: string;
  apns_registered: boolean;
  legacy_psn_user: boolean;
  eligible_for_free_trial: boolean;
  eligible_for_student_plan: boolean;
  name: string;
  created_at: string;
  bio: string;
  current_plan: CurrentPlan;
  active_recurring_subscriber: boolean;
  avatar_url: string;
  cover_image_url: string;
  show_badge: boolean;
  push_notifications: boolean;
  referrals: {
    referred_count: number;
    gift_goal: number;
    eligible_for_subscription_extension: boolean;
    share_urls: {
      global: string;
      facebook: string;
      twitter: string;
      email: string;
      whatsapp: string;
      imessage: string;
    };
  };
  cookie_consent: CookieConsent;
  should_upsell: boolean;
  payment_method_issue: 'billing_failed' | 'card_expiring' | 'card_expired';
  can_use_mubi_go: boolean;
  lifecycle_phase: LifecyclePhase;
  locale?: Locale;
  subscription?: SubscriptionPlan;
  student: boolean;
  subtitle_size: SubtitleSize;
  content_rating_id: number;
  parental_pin: string;
  subscription_plan_change_ineligible_reason: {
    message: string;
    cta: string;
    url: string;
  } | null;
  retrospective_public_id?: string;
};

export type SubtitleSize = 'large' | 'regular';

export type UserId = number;

export type User = {
  id: UserId;
  name: string;
  initials: string;
  avatar_url: string;
  canonical_url: string;
  bio: string;
  created_at: string;
  cover_image_url: string;
  show_badge: boolean;
  country: Country;
  seo_indexable: boolean;
  ratings_count: number;
  wishes_count: number;
  lists_count: number;
  followees_count: number;
  followers_count: number;
  private: boolean;
  favorite_films_count?: number;
  favorite_cast_members_count?: number;
};

export type Fanship = {
  id: number;
  created_at: string;
  fannable: Film | CastMember;
};

export type OnboardingOffer = PromoBanner & {
  special_promo: Promo;
};

export type UserPreferences = CommunicationPreferences & {
  newsletters: {
    daily_film_newsletter: boolean;
    weekly_film_newsletter: boolean;
    mubi_go_newsletter: boolean;
  };
  mailchimp_list_subscriptions: {
    mubi_users: boolean;
  };
  video: {
    cdn: string;
    max_quality: string;
    subtitle_size: string;
    display_content_warnings: boolean;
  };
  marketing_emails: {
    offers?: boolean;
    general?: boolean;
    welcome_series?: boolean;
  };
  psn: {
    presence: boolean;
    sharing: boolean;
  };
  email_notifications: {
    film_showing: boolean;
    film_near_expiry: boolean;
    followed_user: boolean;
    liked_rating: boolean;
    followed_list: boolean;
    commented_on_list: boolean;
    gift_film_prompt: boolean;
    commented_on_rating?: boolean;
  };
  push_notifications: {
    enabled: boolean;
  };
  cookie_consent: {
    functional: boolean;
    analytics: boolean;
    marketing: boolean;
  };
  content: {
    prefer_local_language: boolean;
  };
  communications: {
    opted_out: boolean;
  };
  subscription: {
    renewal_reminder: boolean;
  };
};

export type CommunicationPreferences = {
  streaming: {
    curated_films: 'true' | 'false';
    watchlist: 'true' | 'false';
    weekly_digest: 'true' | 'false';
  };
  cinemas: {
    mubi_go: 'true' | 'false';
    mubi_releases: 'true' | 'false';
  };
  read_listen: {
    notebook: 'true' | 'false';
    podcast: 'true' | 'false';
  };
  more: {
    offers: 'true' | 'false';
    likes: 'true' | 'false';
  };
};

export type AccountSettings = {
  id: number;
  birth_date: string;
  country: Country;
  country_id?: number;
  email: string;
  locale: Locale;
  name: string;
  new_email: string;
  private: boolean;
};

export type UserStateBannerType = 'notice' | 'alert';

type UserFeedbackBannerType = 'feedback' | 'feedback_error';

export type MessageType = UserStateBannerType | UserFeedbackBannerType;

export type UserBanner<T> = { type: T; message: string };

export type UserStateBanner = UserBanner<UserStateBannerType>;

type DeepPartial<T> = Partial<{ [P in keyof T]: DeepPartial<T[P]> }>;

export type UserFactory = {
  plan?: 'premium' | 'basic' | 'student' | 'none';
  status?: SubscriptionStatus;
  country?: string;
  renewalPeriod?: 'monthly' | 'yearly';
  overrides?: DeepPartial<CurrentUser>;
};

export const getOnboardingOffer = (
  httpContext: ObjectOfStrings,
  offerId: number,
): { data: OnboardingOffer } =>
  HttpService(httpContext).get(`/onboarding_offers/${offerId}`);

type UpdatedUserProps = Partial<
  Pick<
    CurrentUser,
    | 'name'
    | 'bio'
    | 'avatar_url'
    | 'cover_image_url'
    | 'communication_opt_out'
    | 'cookie_consent'
  >
>;

export type SubscriptionCancellationType =
  | 'grandfather'
  | 'keep_discount_plan'
  | 'offer_pause'
  | 'offer_trial_extension'
  | 'offer_extension'
  | 'offer_discount'
  | 'complete';

export type SubscriptionCancellation = {
  id: number;
  reason: CancellationReasonKey;
  details: string;
  net_promoter_score: number;
  steps: SubscriptionCancellationType[];
  offer_pause?: {
    pause_period: number;
  };
  grandfather?: {
    type: string;
    subscription_price: string;
    grandfather_price: string;
    period_label: 'month' | 'year';
  };
  keep_discount_plan?: {
    period_label: 'month' | 'year';
    discount_price: string;
    subscription_price: string;
  };
  offer_extension?: Promo;
  offer_trial_extension?: Promo;
  offer_discount?: Promo & {
    total_money_saved: string;
    discounted_monthly_price: string;
  };
  film_recommendation?: FilmRecommendation;
};

export const persistUpdateUser = (
  httpContext: ObjectOfStrings,
  propertiesToUpdate: UpdatedUserProps,
): Promise<{ data: CurrentUser; status: number }> =>
  HttpService(httpContext).put('/current_user', { data: propertiesToUpdate });

export const userStartUpInit = (
  httpContext: ObjectOfStrings,
): Promise<{ data: AppStartUp }> =>
  HttpService(httpContext).post('/app_startup');

export const getCurrentUser = (
  httpContext: ObjectOfStrings,
  serverCtx = null,
): Promise<{ data: CurrentUser; status: number }> =>
  HttpService(httpContext, serverCtx).get('/current_user');

export const deleteCurrentUser = (
  httpContext: ObjectOfStrings,
): Promise<{ status: number }> =>
  HttpService(httpContext).deleteRequest('/current_user');

export const deleteUserSession = (
  httpContext: ObjectOfStrings,
): Promise<{
  status: number;
}> => HttpService(httpContext).deleteRequest('/sessions');

export const getCancellationOffer = (
  httpContext: ObjectOfStrings,
): Promise<{
  data: CancellationOffer;
}> => HttpService(httpContext).get('/subscriptions/prevent_cancellation_offer');

export type CancellationReasonKey =
  | 'not_enough_time'
  | 'too_many_subscriptions'
  | 'content_missing_new_exclusive_content'
  | 'content_missing_series_content'
  | 'content_nothing_wanted_to_watch'
  | 'content_not_enjoyed_film'
  | 'product_design'
  | 'product_device_support'
  | 'product_dubbed'
  | 'product_search'
  | 'product_streaming_quality'
  | 'product_subtitles'
  | 'other';

export type CancellationReason = {
  cancellation_reason_key: CancellationReasonKey;
  cancellation_reason_text: string;
  show_free_text_area: boolean;
  free_text_question_text?: string;
  sub_cancellation_reasons?: CancellationReason[];
};

export const getCancellationReasons = (
  httpContext: ObjectOfStrings,
): Promise<{
  data: CancellationReason[];
}> => HttpService(httpContext).get('/subscriptions/cancellation_reasons');

export const putCancellationOffer = (
  httpContext: ObjectOfStrings,
): Promise<{
  status: number;
}> =>
  HttpService(httpContext).put(
    '/subscriptions/redeem_prevent_cancellation_offer',
  );

export const cancelSubscription = (
  httpContext: ObjectOfStrings,
  formData?: ReasonForCancellation,
): Promise<{ data: SubscriptionPlan }> =>
  HttpService(httpContext).deleteRequest('/subscriptions', { data: formData });

type PausePeriod = { pause_period: number };
export const pauseSubscription = (
  httpContext: ObjectOfStrings,
  pausePeriod: PausePeriod,
): Promise<{
  status: number;
}> =>
  HttpService(httpContext).put('/subscriptions/pause', { data: pausePeriod });

export const unpauseSubscription = (httpContext: ObjectOfStrings) =>
  HttpService(httpContext).put('/subscriptions/unpause');

export const createSubscriptionCancellation = (
  httpContext: ObjectOfStrings,
  data: {
    reason_for_cancellation: CancellationReasonKey;
    reason_for_cancellation_details?: string;
  },
): Promise<{ data: SubscriptionCancellation }> =>
  HttpService(httpContext).post('/subscription_cancellations', {
    data,
  });

export const redeemCancellationOffer = (
  httpContext: ObjectOfStrings,
  cancellationReasonId: number,
  offer: SubscriptionCancellationType,
): Promise<{ data: CurrentUser }> =>
  HttpService(httpContext).put(
    `/subscription_cancellations/${cancellationReasonId}/redeem`,
    {
      data: {
        offer,
      },
    },
  );

export const exchangeAutoLoginToken = (
  httpContext: ObjectOfStrings,
  autoLoginToken: string,
): Promise<{
  data: {
    token: string;
  };
}> =>
  HttpService(httpContext).post('/exchange_auto_login_token', {
    data: {
      lt: autoLoginToken,
    },
  });

export const getUserById = (
  httpContext: ObjectOfStrings,
  userId: number,
): Promise<{
  data: User;
}> => HttpService(httpContext).get(`/users/${userId}`);

export type UserAvatarResponse = {
  id: number;
  image_urls: {
    large: string;
    medium: string;
    small: string;
  };
};

export const setUserAvatar = (
  httpContext: ObjectOfStrings,
  userId: number,
  formData: FormData,
): Promise<{
  data: UserAvatarResponse;
}> =>
  HttpService(httpContext).post(`/users/${userId}/avatars`, {
    data: formData,
  });

export const deleteUserAvatar = (
  httpContext: ObjectOfStrings,
  userId: number,
  avatarId: number,
): Promise<{
  status: number;
}> =>
  HttpService(httpContext).deleteRequest(
    `/users/${userId}/avatars/${avatarId}`,
  );

export const setUserCover = (
  httpContext: ObjectOfStrings,
  userId: number,
  formData,
): Promise<{
  data: {
    id: number;
    image_urls: {
      large: string;
      medium: string;
      small: string;
    };
  };
}> =>
  HttpService(httpContext).post(`/users/${userId}/cover_images`, {
    data: formData,
  });

export const deleteUserCover = (
  httpContext: ObjectOfStrings,
  userId: number,
  coverId: number,
): Promise<{
  status: number;
}> =>
  HttpService(httpContext).deleteRequest(
    `/users/${userId}/cover_images/${coverId}`,
  );

export const getReferrals = (
  httpContext: ObjectOfStrings,
): Promise<{ data: { referrals: ReferralsList } }> =>
  HttpService(httpContext).get('/referrals');

export const getReferral = (
  httpContext: ObjectOfStrings,
  userToken: string,
): Promise<{ data: Referral }> =>
  HttpService(httpContext).get(`/referrals/${userToken}`);

export const getReferralToken = (
  httpContext: ObjectOfStrings,
): Promise<{ data: { invite_token: string } }> =>
  HttpService(httpContext).get('/referrals/invite_token');

export const sendReferralEmails = (
  httpContext: ObjectOfStrings,
  emailAddresses: string[],
  inviteToken?: string,
): Promise<{
  status: number;
}> =>
  HttpService(httpContext).post('/invitations', {
    data: { email_addresses: emailAddresses, user_invite_token: inviteToken },
  });

export const getUserPreferences = (
  httpContext: ObjectOfStrings,
): Promise<{ data: UserPreferences }> =>
  HttpService(httpContext).get('/user_preferences');

export const updateUserPreferences = (
  httpContext: ObjectOfStrings,
  propertiesToUpdate: Partial<UserPreferences>,
): Promise<{ data: UserPreferences }> =>
  HttpService(httpContext).put('/user_preferences', {
    data: propertiesToUpdate,
  });

export const getAccountSettings = (
  httpContext: ObjectOfStrings,
): Promise<{ data: AccountSettings }> =>
  HttpService(httpContext).get('/account');

export const updateAccountSettings = (
  httpContext: ObjectOfStrings,
  propertiesToUpdate: Partial<AccountSettings> & { password?: string },
): Promise<{
  status: number;
}> => HttpService(httpContext).put('/account', { data: propertiesToUpdate });

export type UpdateAccountResponse = {
  token: string;
  account: AccountSettings;
};

export const updateAccountEmail = (
  httpContext: ObjectOfStrings,
  newEmail: { new_email: string },
): Promise<{ data: UpdateAccountResponse }> =>
  HttpService(httpContext).put('/account/email', { data: newEmail });

export const validatePassword = (
  newPasswordInput: string,
  confirmPasswordInput: string,
) => {
  if (confirmPasswordInput.length === 0 && newPasswordInput.length === 0) {
    return 'password_empty';
  }
  if (confirmPasswordInput !== newPasswordInput) {
    return 'password_error';
  }
  if (newPasswordInput.length < 6) {
    return 'password_length';
  }
  return null;
};

export const updateAccountPassword = (
  httpContext: ObjectOfStrings,
  newPassword: { password: string },
): Promise<{ data: UpdateAccountResponse }> =>
  HttpService(httpContext).put('/account/password', { data: newPassword });

export const sendPasswordReset = (
  httpContext: ObjectOfStrings,
  emailAddress: string,
): Promise<
  Response<{
    errors?: [string];
    error?: string;
    user_message?: string;
    message?: string;
  }>
> =>
  HttpService(httpContext).post('/password_reset', {
    data: { identifier: emailAddress },
  });

export const getPasswordReset = (
  httpContext: ObjectOfStrings,
  resetPasswordToken: string,
  userId: string,
): { data: { email: string } } =>
  HttpService(httpContext).get(
    `/password_reset?reset_password_token=${resetPasswordToken}&user_id=${userId}`,
  );

export const setPasswordReset = (
  httpContext: ObjectOfStrings,
  resetPasswordToken: string,
  userId: number,
  data: { password: string; password_confirmation: string },
): { data: { token: string } } =>
  HttpService(httpContext).put(
    `/password_reset?reset_password_token=${resetPasswordToken}&user_id=${userId}`,
    { data },
  );
